import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { Icon, Link } from '@simplybusiness/mobius';
import { telephone } from '@simplybusiness/icons';
import { useTranslation } from 'react-i18next';

import { sanitizePhoneNumber } from '../../lib/phoneNumbers';

interface Props {
  page: string
}

const StyledLink = styled(Link)`
  display: flex;
  color: var(--color-text);
  margin: 0;
  font-weight: var(--font-weight-bold);
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
  color: var(--color-bubblegum-500);
`;

const CallBlock = ({ page }: Props): ReactElement => {
  const { t } = useTranslation();

  const pageName = page.substring(1).replace(/\//g, '_');

  const phoneNumber = t(`call_block.phone_number.${pageName}`);
  const phoneNumberAsLink = sanitizePhoneNumber(phoneNumber, t('international_phone_prefix'));

  return (
    <StyledLink href={`tel:${phoneNumberAsLink}`}>
      <StyledIcon icon={telephone} title="Call us" />
      {phoneNumber}
    </StyledLink>
  );
};

export default CallBlock;
