import React, { ReactNode } from 'react';
import { Text } from '@simplybusiness/mobius';
import styled from '@emotion/styled';


interface OpeningHoursProps{
  'data-testid'?: string;
  children: ReactNode,
  title: string
}

const Title = styled(Text)`
  margin: var(--size-sm) 0px;
  color: var(--color-text-medium);
`;

const StyledList = styled.div`

  ul{
    list-style-type:none;
    padding:0px;
    margin:0px;

    p{
      line-height: 1;
      margin: 10px 0px;
    }
  }
`;

class OpeningHoursList extends React.Component<OpeningHoursProps> {
  static Item = ({ children }) => <li><Text>{children}</Text></li>;

  render() {
    const { children, title, 'data-testid': testId } = this.props;

    return (
      <StyledList data-testid={testId}>
        {title && <Title elementType="h4">{title}</Title>}
        <ul>
          {children}
        </ul>
      </StyledList>
    );
  }
}

export default OpeningHoursList;
