/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import styled from '@emotion/styled';
import {
  startOfToday, subDays, addDays, isAfter, isBefore, format, parseISO,
} from 'date-fns';

import { useTranslation } from 'react-i18next';
import { Text, Link } from '@simplybusiness/mobius';
import { currentBusinessUnit, SpecialCase, OpeningHoursForDay } from 'lib/businessUnit';
import trackingService from 'services/TrackingService';

import { sanitizePhoneNumber } from '../../lib/phoneNumbers';
import OpeningHoursList from './openingHoursList';
import configurationService from '../../services/ConfigurationService';


type ContactInfoProps = {
  isContractorsCombined?: boolean,
}

const ClaimContactInfoTitle = styled(Text)`
  margin: var(--size-sm) 0px;
  color: var(--color-text-medium);
  font-size: var(--font-size-lead);
`;

const ClaimContactInfo = styled.div`
  margin-top: var(--size-lg);
  margin-bottom: var(--size-sm);
`;

const PhoneNumber = styled(Link)`
  font-size: var(--font-size-lead);
  font-family: var(--font-family);
  color: var(--color-primary);

  @media (min-width: 768px){
    font-size: var(--font-size-small-title);
  }
`;

const ClaimPhoneNumber = styled(Link)`
  font-family: var(--font-family);
  color: var(--color-primary);
`;

const ClaimWebLink = styled(Link)`
  font-family: var(--font-family);
  color: var(--color-primary);
`;

const Header = styled.p`
  color: var(--color-text);
`;

const SupportLink = styled(Link)`
  font-weight: var(--font-weight-bold);
`;

const formatTime = (date: Date): String => format(date, 'h:mmbbb');

const buildSpecialCaseComponent = ({ date, open, close }: SpecialCase): JSX.Element => {
  if (! open || ! close) {
    return (
      <OpeningHoursList.Item key={date}>
        {format(parseISO(date), 'd MMM')}
        <strong> Closed</strong>
      </OpeningHoursList.Item>
    );
  }
  const openingDateTime = new Date(`${date}T${open}`);
  const closingDateTime = new Date(`${date}T${close}`);

  return (
    <OpeningHoursList.Item key={date}>
      {format(openingDateTime, 'd MMM')}
      {' '}
      <strong>
        {formatTime(openingDateTime)}
        {' - '}
        {formatTime(closingDateTime)}
      </strong>
    </OpeningHoursList.Item>
  );
};

export const buildSpecialCasesList = (contactCentre): Array<JSX.Element> => {
  const windowUpperbound = addDays(startOfToday(), 14);
  const windowLowerbound = subDays(startOfToday(), 1);

  const filteredSpecialCasesDates = contactCentre.specialCases.filter(({ date }) => {
    const parsedDate = parseISO(date);

    return ! (isAfter(parsedDate, windowUpperbound) || isBefore(parsedDate, windowLowerbound));
  });

  return filteredSpecialCasesDates.map(buildSpecialCaseComponent);
};

const StyledContactInfo = styled.div`

`;

const getConfigForOpeningHours = (day: string): OpeningHoursForDay | undefined => {
  const { openingHours } = currentBusinessUnit().contactCentre;
  const config = openingHours[day.toLowerCase()];

  if (! config && day !== 'Saturday' && day !== 'Sunday') {
    return openingHours.weekday;
  }

  return config;
};

const renderOpeningHoursItem = (day: string): JSX.Element => {
  const { open, close } = getConfigForOpeningHours(day) || {};
  let time;

  if (! open || ! close) {
    time = 'Closed';
  } else {
    // we need only time, without specific date
    const openingDateTime = new Date(`2020-12-26T${open}`);
    const closingDateTime = new Date(`2020-12-26T${close}`);

    time = `${formatTime(openingDateTime)} - ${formatTime(closingDateTime)}`;
  }

  return (
    <OpeningHoursList.Item key={day}>
      {`${day} `}
      <strong>{time}</strong>
    </OpeningHoursList.Item>
  );
};

const ContactInfo = ({ isContractorsCombined }: ContactInfoProps): JSX.Element => {
  const { contactCentre } = currentBusinessUnit();
  const specialCases = buildSpecialCasesList(contactCentre);

  const { t } = useTranslation();
  const phoneNumber = isContractorsCombined
    ? t('contact_info.vertical_overrides.contractors_combined')
    : t('contact_info.phone_number');
  const phoneNumberAsLink = `tel:${sanitizePhoneNumber(phoneNumber, t('international_phone_prefix'))}`;

  const claimPhoneNumber = contactCentre.phoneNumbers.claim;
  const claimPhoneNumberAsLink = `tel:${sanitizePhoneNumber(claimPhoneNumber, t('international_phone_prefix'))}`;

  const supportLink = configurationService.onlyUKtraffic()
    ? 'https://help.simplybusiness.co.uk/hc/en-gb/'
    : 'https://www.simplybusiness.com/help-center/';


  if (configurationService.onlyUKtraffic()) {
    return <></>;
  }


  return (
    <StyledContactInfo>
      <Text elementType="h3">Need some help?</Text>
      <Header>{t('contact_info.header')}</Header>
      <Header>
        {'Or, contact our online support team through our '}
        <SupportLink
          data-testid="support-web-link"
          href={supportLink}
          onClick={() => trackingService.trackLinkClick({
            url: supportLink,
            label: 'policy_details.help_and_support',
          })}
        >
          "Help and Support"
        </SupportLink>
        {' page.'}
      </Header>
      <PhoneNumber
        href={phoneNumberAsLink}
        data-testid="contact-info-phone-number"
      >
        {phoneNumber}
      </PhoneNumber>
      <OpeningHoursList title="Opening hours" data-testid="opening-hours">
        {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(renderOpeningHoursItem)}
      </OpeningHoursList>
      {(specialCases.length > 0) && (
        <OpeningHoursList title="Special opening hours" data-testid="special-opening-hours">
          {specialCases}
        </OpeningHoursList>
      )}

      {configurationService.shouldShowClaimContactDetails() && (
        <ClaimContactInfo data-testid="contact-for-claim">
          <ClaimContactInfoTitle>Calling about a claim?</ClaimContactInfoTitle>
          We're here 24/7, all year round on
          {' '}
          <ClaimPhoneNumber
            data-testid="claims-number"
            href={claimPhoneNumberAsLink}
            onClick={() => trackingService.fireAccountEvent('account_claims_number_clicked')}
          >
            <strong>
              {claimPhoneNumber}
            </strong>
          </ClaimPhoneNumber>
          {', also via '}
          <ClaimWebLink
            data-testid="claims-web-link"
            href="https://www.simplybusiness.co.uk/insurance-claims/"
            onClick={() => trackingService.trackLinkClick({
              url: 'https://www.simplybusiness.co.uk/insurance-claims/',
              label: 'policy_details.make_claim',
            })}
          >
            "making a claim"
          </ClaimWebLink>
          {' online.'}
        </ClaimContactInfo>
      )}
    </StyledContactInfo>
  );
};

export default ContactInfo;
